<template>
    <layout-default>
        <section class="services bg-light" id="termin">
            <div class="container">
                <div class="text-center">
                    <h2 class="text-uppercase lined lined-center">Unsere <span class="text-primary">Handouts</span> für Sie</h2>
                    <p class="text-muted mb-5">Einfach downloaden, lesen und teilen.</p>
                </div>
                <div class="row gy-4">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card border-0 h-100">
                            <div class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-left align-items-left">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                        <div class="p-4 border border-2 border-white rounded-circle" style="border-width: 2px !important;">
                                            <i class="far fa-2x fa-file-pdf"></i>
                                        </div>
                                        </div>
                                        <div class="ms-3">
                                        <h3 class="h5"><a href="/Portfolio_Heime.pdf" target="_blank">Portfolio für Senioren- und Pflegeheime / Tagesbetreuung</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gy-4 mt-4">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card border-0 h-100">
                            <div class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-left align-items-left">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                        <div class="p-4 border border-2 border-white rounded-circle" style="border-width: 2px !important;">
                                            <i class="far fa-2x fa-file-pdf"></i>
                                        </div>
                                        </div>
                                        <div class="ms-3">
                                        <h3 class="h5"><a href="/Portfolio_Kindergarten.pdf" target="_blank">Portfolio für Kindertagesstätten</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gy-4 mt-4">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card border-0 h-100">
                            <div class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-left align-items-left">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                        <div class="p-4 border border-2 border-white rounded-circle" style="border-width: 2px !important;">
                                            <i class="far fa-2x fa-file-pdf"></i>
                                        </div>
                                        </div>
                                        <div class="ms-3">
                                        <h3 class="h5"><a href="/Portfolio_Arzt.pdf" target="_blank">Portfolio für Ärzte</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

export default {
    name: 'Kontakt',
    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    components: {
        LayoutDefault
    }
};
</script>
